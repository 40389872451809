:root {
  --error: #EC1824;
  --error-bg: #FFD6D6;
  --success: #2BD97B;
  --success-bg: #EBFFF4;
  --primary-500: #99681F;
  --primary-600: #CC9139;
  --secondary-500: #FFB647;
  --secondary-400: #FFCA7A;
  --secondary-200: #FFEFD6;
  --secondary-100: #FFF9F0;
  --neutral: #FFFFFF;
  --neutral-opacity: rgba(255, 255, 255, .85);
  --neutral-100: #FDFCFC;
  --neutral-200: #F2F2F2;
  --neutral-300: #D9D9D9;
  --neutral-400: #BFBFBF;
  --text-900: #333333;
  --text-700: #666666;
  --text-600: #8F8F8F;
  --text-500: #ADADAD;
  --s:#1976d2;
  --box-shadow:0px 0.0625em 0.125em 0 rgba(0, 0, 0, 0.10);
}
