* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  @apply m-0 text-text900;
  font-family: Inter, sans-serif;
  line-height: 1.7;
  font-size: 5vw !important;
  @screen lg {
    font-size: .9vw !important;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  display: none;
}

@screen lg {
  .container {
    @apply mx-auto;
    max-width: 75em;
  }
}

.container-sm {
  @apply mx-auto;
  max-width: 32em;
}

hr {
  @apply border-neutral300;
}

@layer utilities {

  .items-col-center-center {
    @apply flex flex-col justify-center items-center;
  }

  .items-col-center-start {
    @apply flex flex-col justify-center items-start;
  }

  .items-row-center-start {
    @apply flex flex-row justify-center items-start;
  }

  .items-row-center-end {
    @apply flex flex-row justify-center items-end;
  }

  .items-row-center-center {
    @apply flex flex-row justify-center items-center;
  }

  .items-col-start-center {
    @apply flex flex-col justify-start items-center;
  }

  .items-row-start-center {
    @apply flex flex-row justify-start items-center;
  }

  .items-row-end-center {
    @apply flex flex-row justify-end items-center;
  }

  .items-row-end-start {
    @apply flex flex-row justify-end items-start;
  }

  .items-row-between-start {
    @apply flex flex-row justify-between items-start;
  }


  .items-row-between-center {
    @apply flex flex-row justify-between items-center;
  }


  .items-row-between-end {
    @apply flex flex-row justify-between items-end;
  }


  .items-row-around-center {
    @apply flex flex-row justify-around items-center;
  }

  .items-col-between-center {
    @apply flex flex-col justify-between items-center;
  }


  .items-col-start-start {
    @apply flex flex-col justify-start items-start;
  }

  .items-col-start-end {
    @apply flex flex-col justify-start items-end
  }

  .items-col-end-center {
    @apply flex flex-col justify-end items-center
  }

  .items-row-start-start {
    @apply flex flex-row justify-start items-start;
  }

  /* end of flex */


}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.cdk-overlay-container {
  @apply fixed;
  z-index: 1000;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  @apply w-full h-full top-0 left-0 pointer-events-none;
}

.cdk-overlay-container:empty {
  @apply hidden;
}

.cdk-overlay-backdrop {
  @apply opacity-0 absolute inset-0 pointer-events-auto;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-backdrop-showing {
  @apply opacity-100;
}

.cdk-global-overlay-wrapper {
  @apply flex absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  @apply absolute pointer-events-auto box-border flex max-w-full max-h-full;
  z-index: 1000;
}

.overlay-panel {
  @apply bg-neutral  p-20;
  box-shadow: 0 0 0.625em 0 rgba(0, 0, 0, 0.25);
}

.gm-style-iw-d {
  @apply overflow-visible p-0 #{!important};
}

.gm-style, .gm-style-iw, .gm-style-iw-c {
  @apply p-0 #{!important};
}

.gm-style-iw-chr {
  @apply absolute right-0 z-10;
}

.gmnoprint, .gm-style-mtc-bbw, .gmnoprint, .gm-bundled-control, .gm-bundled-control-on-bottom {
  @apply hidden #{!important};
}
